const tableData = [ 
  {
    title: "Архитектурная премия Москвы",
    nomination: "Лучшее архитектурно-градостроительное решение объекта дорожно-транспортной инфраструктуры",
    prize: "Победитель",
    year: "/_2024/",
  },
  {
    title: "Real Estate Property Awards",
    nomination: "Архитектурные концепции",
    prize: "Финалист",
    year: "/_2024/",
   },
   {
    title: "100 лучших объектов России",
    nomination: "Лучшие конструктивные решения",
    prize: "Победитель",
    year: "/_2023/",
   },
   {
    title: "Рекорды рынка недвижимости",
    nomination: "Клубный дом №1 города Москвы",
    prize: "Победитель",
    year: "/_2023/",
  },
  {
    title: "Лидер стройкомплекса России",
    nomination: "Лучшая проектно-изыскательская организация",
    prize: "Победитель",
    year: "/_2022/",
   },
   {
    title: "Рейтинг лучших работодателей России",
    nomination: "Строительство и недвижимость",
    prize: "TOP 10",
    year: "/_2022/",
   },
   {
    title: "European Property Awards",
    nomination: "Лучшая архитектура, Лучшее ландшафтное благоустройство, Лучший интерьер",
    prize: "Победитель",
    year: "/_2022/",
   },
   {
    title: "Стекло в архитектуре",
    nomination: "Офисно-деловое здание",
    prize: "Серебряный диплом",
    year: "/_2022/",
   },
   {
    title: "Премия Москомархитектуры",
    nomination: "Лучшее АГР объекта транспортной инфраструктуры",
    prize: "Победитель",
    year: "/_2022/",
   },
   {
    title: "REPA Премия",
    nomination: "Репутация компании. Проектное бюро",
    prize: "Победитель",
    year: "/_2022/",
   },
   {
    title: "Премия Urban Awards",
    nomination: "Лучший жилой небоскрёб премиум-класса Москвы",
    prize: "Победитель",
    year: "/_2022/",
   },
   {
    title: "Премия Urban Awards",
    nomination: "Лучший комплекс апартаментов премиум-класса Москвы",
    prize: "Победитель",
    year: "/_2021/",
   },
   {
    title: "World Travel Awards",
    nomination: "Лучшая достопримечательность Европы в сфере винного туризма",
    prize: "Победитель",
    year: "/_2021/",
   },
   {
    title: "World Architecture Festival (WAF)",
    nomination: "Residential, Re-Use, Masterplanning",
    prize: "Победитель",
    year: "/_2019/",
   },
];

export default tableData;