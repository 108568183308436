import React from "react";
import styles from "./Information.module.css";

const Information = () => { 

  return (
    <div className={styles.information_wrapper}>
      <h1 className={styles.information_h1}>
        Согласие на&nbsp;обработку персональных данных (включая cookie)
      </h1>
      <p className={styles.information_p}>
        Настоящим, в&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;27.07.2006 №&nbsp;152-ФЗ
        «О&nbsp;персональных данных», вы&nbsp;подтверждаете свое согласие на&nbsp;обработку
        ООО&nbsp;«МАРКС ИНЖИНИРИНГ» юридический адрес: 125040, г.&nbsp;Москва, 3-я&nbsp;улица&nbsp;Ямского
        поля, д.&nbsp;20, стр.&nbsp;1, в&nbsp;том числе Группе компаний Марксгрупп (далее&nbsp;–&nbsp;Марксгрупп)
        Ваших персональных данных.
      </p>
      <p className={styles.information_p}>
        Под персональными данными подразумевается любая информация (включая
        cookies), относящаяся к&nbsp;прямо или&nbsp;косвенно к&nbsp;Вам, включая, но&nbsp;не&nbsp;ограничиваясь:
        фамилия, имя, отчество, дата рождения, контактный
        телефон, адрес электронной почты, IP&nbsp;адрес, информация об&nbsp;используемом
        браузере, время доступа к&nbsp;программному обеспечению и/или&nbsp;любому сайту,
        сервису, услуге Марксгрупп, файлы cookies.
      </p>
      <p className={styles.information_p}>
        Настоящим согласием вы&nbsp;подтверждаете, что&nbsp;проинформированы о&nbsp;том, что&nbsp;под
        обработкой персональных данных понимаются действия с&nbsp;персональными
        данными, определённые в&nbsp;Федеральном законе №&nbsp;152-ФЗ от&nbsp;27.07.2006 «О&nbsp;персональных данных»,
        а&nbsp;именно: сбор, запись, систематизация,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передача (предоставление, доступ), блокирование, удаление
        персональных данных, совершаемые использованием средств автоматизации
        Марксгрупп.
      </p>
      <p className={styles.information_p}>
        Данным согласием вы&nbsp;подтверждаете, что&nbsp;проинформированы о&nbsp;том, что&nbsp;обработка
        предоставляемых вами персональных данных может осуществляется
        для&nbsp;достижения следующей цели: исполнения обязательств Марксгрупп по&nbsp;заказам,
        договорам и&nbsp;иным обязательствам, принятым Marksgroup в&nbsp;качестве
        обязательных к&nbsp;исполнению перед Вами. Для&nbsp;указанной цели Marksgroup
        реализует комплекс действий, направленных на&nbsp;выполнение следующих задач:
      </p>
      <p className={styles.information_p}>
        – идентификация стороны в&nbsp;рамках оказания Услуги;
      </p>
      <p className={styles.information_p}>
        – предоставление пользователю персонализированных Услуг;
      </p>
      <p className={styles.information_p}>
        – предоставления Пользователю возможности использования программного
        обеспечения, сервисов и&nbsp;услуг, включая создание учетной записи,
        авторизацию под&nbsp;учетной записью;
      </p>
      <p className={styles.information_p}>
        – улучшение качества Услуг и&nbsp;разработка новых;
      </p>
      <p className={styles.information_p}>
        – проведение статистических и&nbsp;иных исследований, на&nbsp;основе обезличенных
        данных;
      </p>
      <p className={styles.information_p}>
        – предоставление персональных данных пользователя правообладателям,
        дистрибьюторам или&nbsp;реселлерам программного обеспечения в&nbsp;целях
        регистрации программного обеспечения на&nbsp;имя пользователя или&nbsp;организации,
        интересы которой представляет пользователь;
      </p>
      <p className={styles.information_p}>
        – предоставление пользователям Веб-сайта безопасного и&nbsp;удобного
        функционала по&nbsp;его использованию, эффективному отображению информации;
      </p>
      <p className={styles.information_p}>
        – исполнения обязательств по&nbsp;договорам с&nbsp;Пользователем, работодателем
        Пользователя, контрагентом Пользователя;
      </p>
      <p className={styles.information_p}>
        – установления с&nbsp;Пользователем обратной связи (в&nbsp;том числе посредством
        мессенджеров WhatsApp и&nbsp;Telegram, социальных сетей ВКонтакте, программой
        для&nbsp;организации видеоконференций Zoom);
      </p>
      <p className={styles.information_p}>
        регистрация пользователей Веб-сайта на&nbsp;мероприятия;
      </p>
      <p className={styles.information_p}>
        – выявления угроз безопасности для&nbsp;программного обеспечения, услуг и&nbsp;сервисов,
        пользователей, Марксгрупп и/или&nbsp;третьих&nbsp;лиц, в&nbsp;том&nbsp;числе
        проверка благонадежности Пользователя при&nbsp;заключении договоров с&nbsp;использованием
        программного обеспечения, услуг и&nbsp;сервисов.
      </p>
      <p className={styles.information_p}>
        – направления Пользователю рекламной информации (в&nbsp;том&nbsp;числе в&nbsp;форме
        рекламной рассылки) с&nbsp;использованием любых средств связи;
      </p>
      <p className={styles.information_p}>
        Настоящее согласие распространяется на&nbsp;следующие персональные данные:
        фамилия, имя, отчество, дата рождения, контактный телефон, сведения об&nbsp;организации,
        должность, номер&nbsp;факса, номер сотового телефона, адрес
        электронной почты, сведения о&nbsp;стране, городе, области проживания, IP&nbsp;адрес,
        информация об&nbsp;используемом браузере, время доступа к&nbsp;программному
        обеспечению и/или&nbsp;любому сайту, сервису, услуге Marksgroup, файлы
        cookies.
      </p>
      <p className={styles.information_p}>
        Срок действия Вашего согласия ограничен сроком, требующимся для&nbsp;достижения
        цели обработки персональных данных, если иной срок хранения
        персональных данных не&nbsp;установлен действующим законодательством, однако,
        Вы вправе в&nbsp;любой момент отозвать настоящее согласие, путём направления
        письменного уведомления на адрес: 125040, г.&nbsp;Москва, 3-я&nbsp;улица Ямского
        поля, д.&nbsp;20, стр.&nbsp;1, в&nbsp;«ГК Марксгрупп», с&nbsp;пометкой «отзыв
        согласия на обработку персональных данных». Удаление ваших персональных
        данных будет произведено Марксгрупп в&nbsp;течении 30&nbsp;дней с&nbsp;момента
        получения данного уведомления.
      </p>
      <p className={styles.information_p}>
        Марксгрупп гарантирует соблюдение следующих прав субъекта персональных
        данных: право на&nbsp;получение сведений о&nbsp;том, какие персональные данные
        субъекта персональных данных хранятся у&nbsp;Марксгрупп; право на&nbsp;удаление,
        уточнение или&nbsp;исправление хранящихся у&nbsp;Марксгрупп персональных данных;
        иные права, установленные действующим законодательством Российской&nbsp;Федерации.
      </p>
      <p className={styles.information_p}>
        Персональные данные Пользователя хранятся в&nbsp;течение всего срока действия
        пользовательских соглашений с&nbsp;данным Пользователем, а&nbsp;также в&nbsp;течение 10&nbsp;лет
        после прекращения их &nbsp;действия, если персональные данные не&nbsp;были
        отозваны лично Пользователем.
      </p>
      {/* <p className={styles.information_p}>
        В Группу компаний Марксгрупп входят следующие юридические лица: ......????????
      </p> */}
    </div>
  );
};

export default Information;
